import React, { useState, useEffect, useRef } from 'react'
import axios from '../Login/axiosConfig'
import { TextField, Button, Paper, Stack, Typography, Box, Link, Fab } from '@mui/material';



const Cultural = ({
    userFull,
    setShowMessages }) => {

    const [tradition, setTradition] = useState("");
    const [appearance, setAppearance] = useState("")
    const [storyValues, setStoryValues] = useState("")

    useEffect(() => {
        if (userFull) {
            setTradition(userFull.tradition)
            setAppearance(userFull.appearance)
            setStoryValues(userFull.storyValues)

        }
    }, [userFull])

    const updateProfile = () => {
        axios.post('/api/v1/updateProfile', {
            tradition,
            appearance,
            storyValues
        })
            .then(() => {
                setShowMessages({
                    alertType: "success",
                    title: "Success",
                    message: "Update completed.",
                })

            }).catch(error => {

                console.log("error", error)
                setShowMessages({
                    alertType: "error",
                    title: "There was an error processing request",
                    message: "Please try back at a later time",
                })
            });
    }


    return (
        <div style={{
            backgroundColor: "#F5F7F8",
            minHeight: "1024px",
            // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23262f71' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
        }}>
            <div style={{
                display: "flex", justifyContent: "space-between", flexDirection: "column",
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingTop: "15px",
                paddingBottom: "15px",

            }}>
                <Stack width={"100%"} direction="column" marginTop={5} spacing={4}>
                    <Typography marginTop={0} sx={{ fontSize: 26, typography: { fontFamily: "Inter", fontWeight: 900 }, lineHeight: '90%', color: "#212325" }} fontStyle={"normal"} component="div">
                        Cultural Information and Preferences
                    </Typography>
                    <Stack>
                        <Typography marginTop={0} sx={{ fontSize: 16, typography: { fontFamily: "Inter", fontWeight: 600 }, lineHeight: '90%', color: "#212325" }} fontStyle={"normal"} >
                            Can you share some key aspects of your cultural background or traditions that you would like to see reflected in the story? (e.g., traditional foods, festivals, folklore)
                        </Typography>
                        <TextField
                            autoFocus={false}
                            margin="dense"
                            onChange={(e) => {
                                setTradition(e.target.value)
                            }}
                            id="tradition"
                            label=""
                            type="text"
                            fullWidth
                            multiline
                            value={tradition}
                            variant="standard"
                            sx={{ '& .MuiInputBase-root': {
                                color: "#006ec2",
                                opacity: 0.7,
                                borderRadius: 1,
                                typography: { fontFamily: "Inter", fontWeight: 500 }, fontSize: 16,
                                
                            },}}
                        />
                    </Stack>

                    <Stack>
                        <Typography marginTop={0} sx={{ fontSize: 16, typography: { fontFamily: "Inter", fontWeight: 600 }, lineHeight: '90%', color: "#212325" }} fontStyle={"normal"} >
                            How would you describe the appearance and personality traits of the main characters? Are there specific cultural attire or symbols you would like incorporated?
                        </Typography>
                        <TextField
                            autoFocus={false}
                            margin="dense"
                            onChange={(e) => {
                                setAppearance(e.target.value)
                            }}
                            id="appearance"
                            label=""
                            type="text"
                           fullWidth
                           multiline
                            value={appearance}
                            variant="standard"
                            sx={{ '& .MuiInputBase-root': {
                                color: "#006ec2",
                                opacity: 0.7,
                                borderRadius: 1,
                                typography: { fontFamily: "Inter", fontWeight: 500 }, fontSize: 16,
                                
                            },}}
                        />
                    </Stack>
                    <Stack>

                        <Typography marginTop={0} sx={{ fontSize: 16, typography: { fontFamily: "Inter", fontWeight: 600 }, lineHeight: '90%', color: "#212325" }} fontStyle={"normal"} >
                            What themes or values are important to you and should be highlighted in the story? (e.g., family, respect, perseverance)                        </Typography>
                        <TextField
                            autoFocus={false}
                            margin="dense"
                            onChange={(e) => {
                                setStoryValues(e.target.value)
                            }}
                            id="storyValues"
                            label=""
                            type="text"
                            fullWidth
                            multiline
                            value={storyValues}
                            variant="standard"
                            sx={{ '& .MuiInputBase-root': {
                                color: "#006ec2",
                                opacity: 0.7,
                                borderRadius: 1,
                                typography: { fontFamily: "Inter", fontWeight: 500 }, fontSize: 16,
                                
                            },}}
                        />
                    </Stack>
                    <Stack marginTop={3}>




                        <Stack width={"100%"} direction="row" spacing={2} marginTop={2} justifyContent={"flex-end"} >

                            <Button onClick={() => {
                                setTradition("")
                                setAppearance("")
                                setStoryValues("")
                            }}>Clear</Button>
                            <Button onClick={updateProfile} >Update</Button>
                        </Stack>

                    </Stack>


                </Stack>

            </div>
        </div>

    )

}

export default Cultural