import React, { useState } from 'react'


import { Stack, IconButton, Button, Typography } from '@mui/material';
import logo from '../assets/images/DataSnack-full-blue.png'
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AddIcon from '@mui/icons-material/Add';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';




const Header = ({ isAuthenticated, currentScreen, handleDrawerToggle, handleScreenChangeClick, setShowMessages, user }) => {



    return (

        <div

            style={{
                display: "flex", width: "100%",


            }}
        >

            <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} width={"100%"}  >

                <Stack onClick={() => {
                    handleScreenChangeClick({
                        selectedIndex: "HomePage"
                    }
                    )
                }} style={{ cursor: "pointer", display: "flex", flexDirection: "row", width: "20%", alignItems: "center" }}>
                    <img src={logo} alt="Location Image" style={{ width: '180px', paddingRight: "15px" }} />

                </Stack>

                <Stack direction={"row"} spacing={window.$isMobile ? 1 : 2} alignItems={"center"}>

                    {/* <Stack onClick={
                        () => {
                            setShowMessages({
                                alertType: "success",
                                title: "AI StorySnack Creator Coming Soon!",
                                message: "Please create an account, and we will notify you as soon as the AI StorySnack creator becomes available.",
                            })


                            handleScreenChangeClick({
                                selectedIndex: "INTERVIEW_SNACK"
                            })

                        }}>
                        {window.$isMobile ? (<AddIcon
                            variant={'body2'}
                            sx={{
                                // fontSize: 20,
                                // animation: `${pulsate} 1.5s infinite`,
                                color: "#006ec2",
                                opacity: 0.8
                            }} />) : (<Typography
                                variant={'body2'}

                                sx={{
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                    textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 700 },
                                    lineHeight: '100%', color: "#006ec2"
                                }} fontStyle={"normal"}>
                                Create StorySnack
                            </Typography>)}
                    </Stack> */}


                    <IconButton
                        sx={{
                            fontSize: 20,
                            color: "#006ec2",
                            opacity: 0.6
                        }}
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                    // sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuOpenIcon />
                    </IconButton>
                </Stack>


            </Stack>







        </div>


    )


}

export default Header