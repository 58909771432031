import React, { useEffect, useState, useRef } from 'react'

import { Stack, Typography, Box, Backdrop, CircularProgress, Divider } from '@mui/material';

import useWindowDimensions from './useWindowDimensions';
import InfiniteScroll from 'react-infinite-scroll-component';

import Footer from '../Components/Footer';
import SeriesCardNew from '../Components/SeriesCardNew';
import SignUp from '../Components/SignUp';


const HomePage = ({
    openLogin,
    getSnackMediaPublic,
    getSnackMediaPrivate,
    snackMediaPublic,
    snackMediaPrivate,
    setShowMessages,
    handleScreenChangeClick,
    isAuthenticated,
    user,
    checkForProcessingMedia

}) => {
    const { height, width } = useWindowDimensions();
    const [feedIndex, setFeedIndex] = useState(0);
    const [showProgress, setShowProgress] = useState(false)
    const [randomSnack, setRandomSnack] = useState(null)




    useEffect(() => {
        // console.log("in HomePage", window.$timeSinceLastLoginMins)
        window.scrollTo(0, 0)
        if (window.$timeSinceLastLoginMins < 5 && !isAuthenticated) {
            setShowMessages({
                alertType: "info",
                title: <Typography
                    variant={'h6'}

                    sx={{

                        textAlign: "left", typography: { fontFamily: "Inter", fontWeight: window.$isMobile ? 700 : 700 },
                        lineHeight: '120%', color: "#006ec2"
                    }} fontStyle={"normal"}>
                    Create Magical Animated Tales with Your Own Voice for Your Little Ones.
                </Typography>,
                // message: `Create Magical Animated Tales with Your Own Voice for Your Little Ones.

                // Free While in Beta`,
                message: <Typography
                    variant={'h7'}

                    sx={{

                        textAlign: "left", typography: { fontFamily: "Inter", fontWeight: window.$isMobile ? 500 : 500 },
                        lineHeight: '120%', color: "#006ec2"
                    }} fontStyle={"normal"}>

                    Welcome to StorySnack by DataSnack, where you can bring your children's favorite tales to life with your own voice.
                    <br /><br />
                    <b>Free While in Beta!</b>
                </Typography>
            })
        }
    }, [])

    // useEffect(() => {
    //     if (!snackMediaPrivate) {
    //         getSnackMediaPrivate()
    //     }
    // }, [isAuthenticated])



    return (
        <div style={{
            backgroundColor: "#212325",
            minHeight: 1024,
            backgroundAttachment: "fixed",
            alignItems: "center"
        }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showProgress}

            >
                <CircularProgress size={50} style={{ color: '#ffffff' }} />
            </Backdrop>




            <Box
                paddingY={2.5}
                paddingTop={5}

                sx={{
                    width: "100%",
                    minHeight: 1024,
                    overflowY: "scroll",
                }}>

                {!isAuthenticated && <Stack marginTop={window.$isMobile ? 0 : 4} marginBottom={window.$isMobile ? 8 : 15}>
                    <SignUp
                        openLogin={openLogin}
                        linkCode={""}
                        randomSnack={randomSnack}
                        handleScreenChangeClick={handleScreenChangeClick}
                        setShowMessages={setShowMessages}

                    />
                </Stack>}

                <Stack spacing={3}
                    paddingX={window.$isMobile ? 1 : 5}
                    width={"100%"}
                    alignItems={"center"}
                    direction={"column"}

                >



                    {snackMediaPublic && <InfiniteScroll
                        dataLength={snackMediaPublic.length} //This is important field to render the next data
                        next={() => {
                            getSnackMediaPublic(feedIndex + 1)
                            setFeedIndex(feedIndex + 1)
                        }}
                        hasMore={true}
                        loader={
                            <Typography
                                variant='h5'
                                paddingY={3}
                                sx={{
                                    textAlign: "center",
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 500 },
                                    color: "#ffffff7f"
                                }} >

                            </Typography>

                        }
                        refreshFunction={() => {
                            console.log("in refresh")
                            getSnackMediaPublic(0)
                        }}
                        pullDownToRefresh
                        pullDownToRefreshThreshold={40}
                        pullDownToRefreshContent={
                            <Typography
                                paddingY={3}
                                variant='h5'
                                sx={{
                                    textAlign: "center",
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 500 },
                                    color: "#ffffff7f"
                                }}  >
                                Pull down to refresh
                            </Typography>

                        }
                        releaseToRefreshContent={
                            <Typography
                                variant='h5'
                                paddingY={3}
                                sx={{
                                    textAlign: "center",
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 500 },
                                    color: "#ffffff7f"
                                }}  >
                                Release to refresh
                            </Typography>
                        }
                    >
                        <Stack direction={"column"}

                            // useFlexGap flexWrap="wrap"
                            justifyContent={"center"} width={"100%"}>
                            {snackMediaPublic && snackMediaPublic.map((e, index) => (
                                <React.Fragment key={e.snackId}>

                                    <Stack
                                        direction={"column"} width={"100%"}>
                                        <SeriesCardNew snackCard={e}
                                            width={width}
                                            user={user}
                                            checkForProcessingMedia={checkForProcessingMedia}
                                            isAuthenticated={isAuthenticated}
                                            openLogin={openLogin}
                                            setShowMessages={setShowMessages}
                                            handleScreenChangeClick={handleScreenChangeClick} />
                                        <Divider sx={{ marginBottom: window.$isMobile ? 4 : 8, marginTop: window.$isMobile ? 3 : 7, backgroundColor: "#ebebeb", opacity: 0.1, width: "100%", borderBottomWidth: 1 }} variant="fullWidth" />
                                    </Stack>


                                </React.Fragment>
                            ))}

                        </Stack>
                    </InfiniteScroll>
                    }
                </Stack>


            </Box>
            <Footer handleScreenChangeClick={handleScreenChangeClick} backgroundColor={"#000000"} />

        </div >
    )

}

export default HomePage

