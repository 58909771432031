import React, { useEffect, useState, useRef } from 'react'

import { Stack, Typography, Box, Backdrop, CircularProgress, Button } from '@mui/material';

import useWindowDimensions from './useWindowDimensions';
import InfiniteScroll from 'react-infinite-scroll-component';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const MySeries = ({
    // allSeries,
    openLogin,
    getMySeries,
    mySeries,
    // getAllSeries,
    getAllMySeries,
    setShowMessages,
    handleScreenChangeClick,
    isAuthenticated,
 

}) => {
    const { height, width } = useWindowDimensions();
    const [feedIndex, setFeedIndex] = useState(0);
    const [showProgress, setShowProgress] = useState(false)



    useEffect(() => {
        console.log("in messenger")
        window.scrollTo(0, 0)

    }, [])

    useEffect(() => {
        if (!mySeries) {
            getMySeries()
        }
    }, [isAuthenticated])


    return (
        <div style={{
            backgroundColor: "#212325",
            minHeight: 1024,
            backgroundAttachment: "fixed",
            alignItems: "center"
        }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showProgress}

            >
                <CircularProgress size={50} style={{ color: '#ffffff' }} />
            </Backdrop>



            <Box
                paddingY={2.5}

                sx={{
                    width: "100%",
                    overflowY: "scroll",
                }}>
                <Stack
                    direction={"row"}
                    paddingX={window.$isMobile ? 2.5 : 5}
                    paddingTop={2}
                    // alignItems={"flex-end"}
                    justifyContent={"flex-start"}>
                    <ArrowBackIcon
                        onClick={() => {
                            console.log("HomePage")
                            handleScreenChangeClick({
                                selectedIndex: "HomePage"
                            })
                        }}
                        sx={{
                            fontSize: window.$isMobile ? 18 : 18, cursor: "pointer", color: "#fcfdfd",
                            opacity: 0.3
                        }} />


                </Stack>

                <Stack spacing={3}
                    paddingX={window.$isMobile ? 1 : 5}
                    width={"100%"}
                    alignItems={"center"}
                    direction={"column"}

                >
                    {/* <Typography
                        variant={window.$isMobile ? 'h5' : 'h3'}
                        paddingY={window.$isMobile ? 0 : 3}
                        paddingX={window.$isMobile ? 5 : 0}
                        sx={{
                            textAlign: "center",
                            textTransform: "none",
                            typography: { fontFamily: "Inter", fontWeight: 200 },
                            color: "#ffffff7f"
                        }} >
                        My StorySnacks
                    </Typography> */}



                    {mySeries && <InfiniteScroll
                        dataLength={mySeries.length} //This is important field to render the next data
                        next={() => {
                            getAllMySeries(feedIndex + 1)
                            setFeedIndex(feedIndex + 1)
                        }}
                        hasMore={true}
                        loader={
                            <Typography
                                variant='h5'
                                paddingY={3}
                                sx={{
                                    textAlign: "center",
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 500 },
                                    color: "#ffffff7f"
                                }} >

                            </Typography>

                        }
                        refreshFunction={() => {
                            console.log("in refresh")
                            getAllMySeries(0)
                        }}
                        pullDownToRefresh
                        pullDownToRefreshThreshold={40}
                        pullDownToRefreshContent={
                            <Typography
                                paddingY={3}
                                variant='h5'
                                sx={{
                                    textAlign: "center",
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 500 },
                                    color: "#ffffff7f"
                                }}  >
                                Pull down to refresh
                            </Typography>

                        }
                        releaseToRefreshContent={
                            <Typography
                                variant='h5'
                                paddingY={3}
                                sx={{
                                    textAlign: "center",
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 500 },
                                    color: "#ffffff7f"
                                }}  >
                                Release to refresh
                            </Typography>
                        }
                    >
                        <Stack direction={"column"} spacing={5}
                            marginTop={2}
                            // useFlexGap flexWrap="wrap"
                            justifyContent={"flex-start"} width={"100%"}>
                            {mySeries && mySeries.map((e, index) => (
                                <Button onClick={() => {
                                    handleScreenChangeClick({
                                        selectedIndex: `PODCAST_SNACK-View`,
                                        snackCard: e

                                    })
                                }}>
                                <Stack key={e.snackId} width={"100%"}  direction={"row"} spacing={window.$isMobile ? 2 : 5} 
                                alignItems={"center"}
                                justifyContent={"flex-start"}>
                                    <img

                                        src={e.thumbnailUrl} alt="Location Image"
                                        style={{ width: "100%", objectFit: "cover", height: window.$isMobile ? 75 : 150, width: window.$isMobile ? 75 :150,  borderRadius: 10 }} />
                                    <Stack direction={"column"} paddingRight={window.$isMobile ? 0 : 40}>
                                        <Typography
                                            variant={window.$isMobile ? 'h7' :'h4'}
                                            sx={{

                                                textAlign: "left", typography: { fontFamily: "Inter", fontWeight: 900 },
                                                lineHeight: '100%', color: "#ffffff"
                                            }} fontStyle={"normal"}>
                                            {e.title}
                                        </Typography>

                                        <Typography
                                            variant={window.$isMobile ? 'body2' :'body1'}
                                            paddingTop={1}
                                            sx={{
                                                textTransform: "none",
                                                textAlign: "left", typography: { fontFamily: "Inter", fontWeight: 600 },
                                                lineHeight: '100%', color: "#ffffff"
                                            }} fontStyle={"normal"}>
                                            {e.subtitle}
                                        </Typography>
                                       {!window.$isMobile && <Typography
                                             variant={window.$isMobile ? 'body2' :'body1'}
                                            paddingTop={2}
                                         

                                            sx={{
                                                display: '-webkit-box',
                                                overflow: 'hidden',
                                                WebkitBoxOrient: 'vertical',
                                                WebkitLineClamp: 5,
                                                textTransform: "none",
                                                whiteSpace: 'pre-wrap', wordWrap: 'break-word',
                                                textAlign: "left", typography: { fontFamily: "Inter", fontWeight: 400 },
                                                lineHeight: '100%', color: "#ebebeb"
                                            }} fontStyle={"normal"}>
                                            {e.description}
                                        </Typography>}




                                    </Stack>

                                </Stack>
                                </Button>
                            ))}

                        </Stack>
                    </InfiniteScroll>
                    }
                </Stack>


            </Box>

        </div >
    )

}

export default MySeries

