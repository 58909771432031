import React, { useEffect, useState, useRef } from 'react'
import axios from '../Login/axiosConfig'
import {
    Avatar, Button, Link, Box, Stack, Chip, Typography, TextField
} from '@mui/material';

import '../Components/audioNewStyle.css'

import SocialShare from '../Components/SocialShare.js'
import ReactPlayer from 'react-player'
import CreateVoice from './CreateVoice.js';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

export default function SeriesCardNew({
    snackCard,
    isOwner,
    handleScreenChangeClick,
    setShowMessages,
    checkForProcessingMedia,
    width,
    user,
    getCurrentUser,
    isAuthenticated,
    openLogin
}) {

    const [voiceName, setVoiceName] = useState("")
    const [showCreateVoice, setShowCreateVoice] = useState(false)
    const [showVoiceName, setShowVoiceName] = useState(false)
    const [showRemixButton, setShowRemixButton] = useState(true)
    const scrollToPlayer = useRef()
    useEffect(() => {
        if (user) {
            if (user.voiceId) {
                setShowCreateVoice(false)
            }

        }

    }, [user])




    const handlePrivacy = (snackId) => {
        axios.post(`/api/v1/updatePrivacy?snackId=${snackId}`, {})
            .then(() => {

                handleScreenChangeClick({
                    updateDash: true
                })
                snackCard.isPrivate = !snackCard.isPrivate
                setShowMessages({
                    alertType: "success",
                    title: "Success!",
                    message: `Series set to ${snackCard.isPrivate ? "private" : "public"}`,
                })


            }).catch(error => {

                console.log("error from audio", error)
                setShowMessages({
                    alertType: "error",
                    title: `Problem setting series to ${snackCard.isPrivate ? "public" : "private"}. Please try again later.`,
                    message: "",
                })
            })
    }

    return (
        <Box >
            <Stack
                width={"100%"}
                paddingX={1.5}
                direction={"column"}
            >

                <Stack sx={{
                    position: "relative",
                    display: "inline-block"
                }}>
                    <img
                        src={snackCard.imageUrl}
                        onError={() => {
                            handleScreenChangeClick({
                                updateDash: true,
                            })
                        }}
                        alt="Location Image"
                        style={{ width: "100%", objectFit: "cover", maxHeight: 350, borderRadius: 10 }} />

                    <PlayCircleIcon
                        onClick={() => {
                            if (scrollToPlayer.current) {
                                scrollToPlayer.current.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'start'
                                });
                            }
                        }}

                        sx={{
                            cursor: "pointer",
                            position: "absolute",
                            fontSize: window.$isMobile ? 44 : 80,
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "#e8454f",
                            opacity: 0.8
                        }} />



                </Stack>

                <Typography

                    paddingTop={2}
                    variant={'h4'}
                    sx={{

                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 900 },
                        lineHeight: '100%', color: "#ffffff"
                    }} fontStyle={"normal"}>
                    {snackCard.title}
                </Typography>
                <Typography
                    variant={'body1'}
                    paddingTop={1}

                    sx={{

                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 600 },
                        lineHeight: '100%', color: "#ffffff"
                    }} fontStyle={"normal"}>
                    {snackCard.subtitle}
                </Typography>
                <Typography
                    variant={'h7'}
                    paddingTop={2}
                    marginBottom={3}
                    paddingX={window.$isMobile ? 0 : 20}

                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 5,
                        whiteSpace: 'pre-wrap', wordWrap: 'break-word',
                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 400 },
                        lineHeight: '100%',
                        color: "#ebebeb"
                    }} fontStyle={"normal"}>
                    {snackCard.description}
                </Typography>
                {snackCard.inspiredDescription && <>
                    <Typography
                        variant={'h6'}
                        paddingTop={3}
                        sx={{

                            textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 900 },
                            lineHeight: '100%', color: "#ffffff"
                        }} fontStyle={"normal"}>
                        Inspired By
                    </Typography>
                    <Stack marginTop={1} width={"100%"} direction={"row"} spacing={2} justifyContent={"center"} alignItems={"center"}>
                        {snackCard.inspiredThumbUrl && <Avatar
                            alt="BJM"
                            src={snackCard.inspiredThumbUrl}

                            sx={{
                                width: window.$isMobile ? 35 : 35,
                                height: window.$isMobile ? 35 : 35,
                                borderRadius: 1,
                                opacity: 0.9
                            }}
                        />}
                        <Link width={window.$isMobile ? "100%" : "30%"} href={snackCard.inspiredUrl ?? "#"} underline="always">
                            <Typography
                                variant={'h7'}

                                sx={{
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2,
                                    whiteSpace: 'pre-wrap', wordWrap: 'break-word',
                                    textAlign: "left", typography: { fontFamily: "Inter", fontWeight: 400 },
                                    lineHeight: '100%',
                                    color: "#ebebeb"
                                }} fontStyle={"normal"} >
                                {snackCard.inspiredDescription}
                            </Typography>
                        </Link>
                    </Stack>
                </>}

                <Typography
                    variant={'h6'}
                    paddingTop={snackCard.inspiredby ? 5 : 2}
                    sx={{

                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 900 },
                        lineHeight: '100%', color: "#ffffff"
                    }} fontStyle={"normal"}>
                    Highlights
                </Typography>

                <Stack spacing={1}
                    paddingTop={3}
                    // marginBottom={window.$isMobile ? 0 : 4}
                    width={"100%"}
                    sx={{ maxHeight: 600, overflowX: 'scroll' }}
                    justifyContent={"center"}
                    direction="row"
                    useFlexGap flexWrap="wrap">
                    {snackCard && snackCard.topics.map((e) => (
                        <Chip
                            variant={'filled'}
                            key={e}
                            label={e}



                            sx={{
                                backgroundColor: '#2d2d2d',
                                padding: 1,
                                fontSize: 16,
                                maxWidth: width * .8,
                                typography: { fontFamily: "Inter" },
                                textTransform: "none", color: "#ffffff",

                            }}
                            onClick={() => {
                                console.log("clicked chip")

                            }} />
                    ))}
                    <div ref={scrollToPlayer} />
                </Stack>

                {snackCard.videoUrl &&
                    <Stack width={"100%"} marginTop={5}>

                        <ReactPlayer
                            controls={true}
                            playbackRate={snackCard.isRemix ? 1.1 : 1.0}
                            width={"100%"}
                            height={"100%"}
                            url={snackCard.videoUrl}
                            onError={(e) => {
                                console.log("player error:", e)

                            }}
                            config={{
                                file: {
                                    attributes: {
                                        crossOrigin: "true",
                                    },
                                    tracks: [
                                        {
                                            kind: 'subtitles',
                                            src: snackCard.videoSubtitleUrl, // URL to your subtitle file
                                            srcLang: 'en',
                                            label: 'English',
                                            default: true // Set as default if needed
                                        },
                                        // Add more subtitle tracks if you have them
                                    ]
                                }
                            }}

                        />

                    </Stack>

                }

                <Stack paddingTop={0}
                    paddingRight={1}
                    direction={"row"}
                    justifyContent={"space-between"}
                    width={"100%"}>
                    {isOwner && <Typography

                        // paddingTop={3}
                        onClick={() => {
                            console.log("isPublic", snackCard.isPublic)
                            handlePrivacy(snackCard.snackId)



                        }}
                        sx={{
                            fontSize: window.$isMobile ? 14 : 14, cursor: "pointer", color: "#fcfdfd",
                            opacity: 0.3,
                            textDecoration: "underline"
                        }} fontStyle={"normal"}>
                        {`Make ${snackCard.isPrivate ? "public" : "private"}`}
                    </Typography>}

                    <SocialShare snackCard={snackCard} buttonColor={"#fcfdfd4c"} />

                </Stack>








                <Stack width={"100%"}
                    marginTop={3}
                    justifyContent={"center"} direction={"row"}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            // setShowMessages({
                            //     alertType: "success",
                            //     title: "StorySnacks Remixer Coming Soon!",
                            //     message: "Please create an account, and we will notify you as soon as the StorySnacks Remixer becomes available.",
                            // })

                            handleScreenChangeClick({
                                selectedIndex: `INTERVIEW_SNACK`,
                                snackCard: snackCard
                            })
                        }}

                        sx={{
                            backgroundColor: "#e8454f",
                            width: window.$isMobile ? "100%" : "75%"
                        }}

                    >
                        <Typography
                            variant={'body1'}
                            sx={{

                                textTransform: "none",
                                typography: { fontFamily: "Inter", fontWeight: 700 },

                                color: "#ffffff"
                            }} >
                            {`Remix ${snackCard.title}`}

                        </Typography>
                    </Button>
                </Stack>

            </Stack >
        </Box >
    );
}


const textBoxDesign = {
    width: "100%",
    '& label.Mui-focused': {
        color: '#ffffff',

    },
    '& .MuiFormLabel-root': {
        color: '#ffffff',
        opacity: 0.9,
    },
    '& .MuiInputBase-root': {
        color: "#ffffff7f",
        borderRadius: 1,
        typography: { fontFamily: "Inter", fontWeight: 400 }, fontSize: 16,
        '& .MuiOutlinedInput-root': {
            color: "#ffffff",

        }
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "#006ec2",
        borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: "#006ec2",
            borderWidth: "1px"
        }
    }


}