import React, { useEffect, useState, useRef } from 'react'
import { Stack, TextField, Button, ToggleButtonGroup, ToggleButton, Typography, Box } from '@mui/material';
import useWindowDimensions from '../Screens/useWindowDimensions.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../Screens/recordButton.css'
import RecordButton from '../Components/RecordButton.js';
import axios from '../Login/axiosConfig'
import CreateVoice from '../Components/CreateVoice.js';




const Interview = ({
    isAuthenticated,
    openLogin,
    setShowMessages,
    handleScreenChangeClick,
    checkForProcessingMedia,
    topPadding,
    snackCard,
    userFull,
    getCurrentUser

}) => {

    useEffect(() => {
        if (snackCard) {
            setStory(snackCard.script)
        }

    }, [snackCard])



    const { height, width } = useWindowDimensions();
    const [token, setToken] = useState(null)

    const [location, setLocation] = useState("Example: Growing up in a small town in southern Alabama.");
    const [appearance, setAppearance] = useState("Example: The main characters should be young African American children dressed in bright, fashionable clothing.")

    const [story, setStory] = useState("")
    const [styleType, setStyleType] = useState("COMIC")

    const [isCustomVoice, setIsCustomVoice] = useState(false)
    const [showCreateVoice, setShowCreateVoice] = useState(false)




    const handleStyleType = (event, value) => {
        setStyleType(value);
    };


    const createStory = (dataObj) => {
        if (!isAuthenticated) {
            setShowMessages({
                alertType: "error",
                title: "Setup a StorySnacks Account",
                message: `Please log in or create an account to continue.`,
            })
            openLogin()
            return
        }

        if (dataObj.story.length < 50) {
            setShowMessages({
                alertType: "error",
                title: "Content Issue",
                message: `Content must be greater than 50 characters and no more than 3000 characters.`,
            })
            return
        }
        setShowMessages({
            alertType: "info",
            timeInSecs: 400,
            showProgress: true,
            title: "Creating StorySnack...",
            message: `Processing will take between 4 to 7 minutes. We will automatically update you when it's finished and send you an email once it's complete. Feel free to leave the page and view other StorySnacks while processing.`,
        })
        axios.post(`/api/v1/autoSnackStory`, dataObj)
            .then(response => {
                console.log("starting checkForProcessingMedia", response.data)
                getCurrentUser()
                checkForProcessingMedia(response.data.snackId)

            }).catch(error => {
                // need to figure out way to handle userFull swiping away from screen and waiting
                console.log("getSnackCard error:", error)

            })
    }



    useEffect(() => {
        console.log("in Interview")
        window.scrollTo(0, 0)

    }, [])

    useEffect(() => {
        if (userFull) {
            setLocation(userFull.tradition)
            setAppearance(userFull.appearance)
        }
    }, [userFull])

    useEffect(() => {
        let myToken = localStorage.getItem('token');
        setToken(myToken)

    }, [isAuthenticated])

    return (

        <Box style={{
            backgroundImage: `linear-gradient(to bottom, #212325 50%, #0f0f0f 100%)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: height,


        }}>

            {/* Back button */}
            <Stack
                direction={"row"}
                paddingX={window.$isMobile ? 2.5 : 5}
                paddingTop={2}
                // alignItems={"flex-end"}
                justifyContent={"flex-start"}>
                <ArrowBackIcon
                    onClick={() => {
                        console.log("HomePage")
                        handleScreenChangeClick({
                            selectedIndex: "HomePage"
                        })
                    }}
                    sx={{
                        fontSize: window.$isMobile ? 18 : 18, cursor: "pointer", color: "#fcfdfd",
                        opacity: 0.3
                    }} />


            </Stack>
            {/* Content Block */}
            <Stack

                paddingX={window.$isMobile ? 2.5 : "20%"}
                style={{
                    display: "flex",
                    // minHeight: height,
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: "flex-start",

                    // width: "100%"
                }}
                direction="column"
                justifyContent={"center"} alignItems={"flex-start"}>

                <Typography
                    paddingTop={4}
                    // paddingBottom={2}
                    variant={'h7'}
                    sx={{

                        textAlign: "left", typography: { fontFamily: "Inter", fontWeight: 600 },
                        lineHeight: window.$isMobile ? '100%' : '100%', color: "#ffffff"
                    }} fontStyle={"normal"}>
                    Create
                </Typography>
                <Typography
                    // paddingTop={4}
                    paddingBottom={2}
                    variant={'h2'}
                    sx={{

                        textAlign: "left", typography: { fontFamily: "Inter", fontWeight: 900 },
                        lineHeight: window.$isMobile ? '100%' : '100%', color: "#ffffff"
                    }} fontStyle={"normal"}>
                    Story<span style={{ color: '#66a8da' }}>{window.$isMobile && <br />}Snacks</span>
                </Typography>

            </Stack>

            {/* Inputs  */}

            <Stack width={"100%"}
                paddingX={window.$isMobile ? 2.5 : "20%"}
                style={{
                    display: "flex",
                    // minHeight: height,
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: "flex-start",

                    // width: "100%"
                }}
                marginTop={5} spacing={4}>


                

                <Stack>
                    <Typography 
                    variant={'h7'}
                    sx={{ paddingLeft:1, paddingRight: "10%", marginBottom:2, typography: { fontFamily: "Inter", fontWeight: 600 }, lineHeight: '120%', color: "#e5f0f8"}} fontStyle={"normal"} >
                        Share some details about where you would like the story to take place. It can be a real or fictional location.
                    </Typography>
                    <TextField
                        autoFocus={false}

                        onChange={(e) => {
                            setLocation(e.target.value)
                        }}
                        id="location"
                        label="Location"
                        type="text"
                        focused
                        fullWidth
                        multiline
                        value={location}
                        variant={'outlined'}
                        margin={"none"}
                        sx={textBoxDesign}
                    />
                </Stack>

                <Stack>
                    <Typography 
                    variant={'h7'}
                    sx={{ paddingLeft:1, paddingRight: "10%", marginBottom:2, typography: { fontFamily: "Inter", fontWeight: 600 }, lineHeight: '120%', color: "#e5f0f8" }} fontStyle={"normal"} >
                        How would you describe the appearance and personality traits of the main characters? Are there specific cultural attire or symbols you would like incorporated?
                    </Typography>
                    <TextField
                        autoFocus={false}

                        onChange={(e) => {
                            setAppearance(e.target.value)
                        }}
                        id="appearance"
                        label="Appearance"
                        type="text"
                        fullWidth
                        multiline
                        focused
                        value={appearance}
                        variant={'outlined'}
                        margin={"none"}
                        sx={textBoxDesign}
                    />
                </Stack>
                <Stack width={"100%"} alignItems={"flex-start"} direction={"column"}>
                    <Typography 
                    variant={'h7'}
                    sx={{ paddingLeft:1, paddingBottom:2, paddingRight: "10%", marginBottom:0, typography: { fontFamily: "Inter", fontWeight: 600 }, lineHeight: '120%', color: "#e5f0f8" }}  fontStyle={"normal"} >
                    Select the visual style you would like to use to remix the StorySnack.
                    </Typography>
                    <ToggleButtonGroup
                        value={styleType}
                        color="primary"
                        exclusive

                        onChange={handleStyleType}
                        aria-label="text alignment"
                    >
                        <ToggleButton value={"COMIC"}

                            aria-label="left aligned">
                            <Typography
                                variant={'body'}
                                sx={{
                                    textAlign: "left", typography: {
                                        fontFamily: "Inter",
                                        textTransform: "none",
                                        fontWeight: styleType === "COMIC" ? 800 : 400
                                    },
                                    lineHeight: '120%', color: "#ffffff"
                                }} fontStyle={"normal"}>
                                Comic
                            </Typography>
                        </ToggleButton>

                        <ToggleButton value={"KIDS"}

                            aria-label="left aligned">
                            <Typography
                                variant={'body'}
                                sx={{
                                    textTransform: "none",
                                    textAlign: "left", typography: {
                                        fontFamily: "Inter",
                                        fontWeight: styleType === "KIDS" ? 800 : 400
                                    },
                                    lineHeight: '120%', color: "#ffffff"
                                }} fontStyle={"normal"}>
                                Animated
                            </Typography>
                        </ToggleButton>

                        <ToggleButton value={"ANIME"}

                            aria-label="right aligned">
                            <Typography
                                variant={'body'}

                                sx={{

                                    textAlign: "left",
                                    textTransform: "none", typography: {
                                        fontFamily: "Inter",
                                        fontWeight: styleType === "ANIME" ? 800 : 400
                                    },
                                    lineHeight: '120%', color: "#ffffff"
                                }} fontStyle={"normal"}>
                                Anime
                            </Typography>
                        </ToggleButton>

                    </ToggleButtonGroup>
                </Stack>
                <Stack width={"100%"}>
                <Typography 
                variant={'h7'}
                sx={{ paddingLeft:1, paddingBottom:2, paddingRight: "10%", marginBottom:0, typography: { fontFamily: "Inter", fontWeight: 600 }, lineHeight: '120%', color: "#e5f0f8" }} fontStyle={"normal"} >
                    Feel free to edit the original script below.
                </Typography>
                <TextField

                    
                    focused
                    variant={'outlined'}
                    margin={"none"}
                    multiline
                    minRows={5}
                    sx={textBoxDesign}
                    id="searchInput"
                    type={"text"}
                    onChange={(e) => {
                        setStory(e.target.value)
                    }}
                    value={story}
                    label={`Edit Script`}


                />
</Stack>
                {showCreateVoice &&
                    <Stack
                        marginY={6}>
                        <CreateVoice
                            checkForProcessingMedia={checkForProcessingMedia}
                            isAuthenticated={isAuthenticated}
                            setShowCreateVoice={setShowCreateVoice}
                            setIsCustomVoice={setIsCustomVoice}
                            openLogin={openLogin}
                            setShowMessages={setShowMessages}
                            getCurrentUser={getCurrentUser}
                            handleScreenChangeClick={handleScreenChangeClick}

                        />
                    </Stack>
                }

                {!showCreateVoice && <Stack width={"100%"}
                    marginTop={3}
                    justifyContent={"center"} direction={"column"}>
                    <Typography 
                    variant={'h7'}
                    sx={{ paddingLeft:1, paddingRight: "10%", marginBottom:2, typography: { fontFamily: "Inter", fontWeight: 600 }, lineHeight: '120%', color: "#e5f0f8" }} fontStyle={"normal"} >
                        Click to use your own voice for the StorySnack. If you haven't created a voice profile before, you'll be prompted to record a conversation with our AI Interviewer.                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => {
                            console.log("userFull from remix", userFull)
                            if (!isAuthenticated) {
                                setShowMessages({
                                    alertType: "error",
                                    title: "Setup a StorySnacks Account",
                                    message: `Please log in or create an account to continue.`,
                                })
                                openLogin()
                                return
                            }

                            if (isCustomVoice) {
                                setIsCustomVoice(false)
                                return
                            }

                            if (userFull) {
                                if (userFull.voiceId) {

                                    setIsCustomVoice(true)
                                } else {


                                    setShowCreateVoice(true)
                                }
                            }


                        }}

                        sx={{
                            backgroundColor: '#006ec2',
                            width: window.$isMobile ? "100%" : "100%"
                        }}

                    >
                        <Typography
                            variant={'body1'}
                            sx={{

                                textTransform: "none",
                                typography: { fontFamily: "Inter", fontWeight: 700 },

                                color: "#ffffff"
                            }} >
                            {isCustomVoice ? `Custom Voice Set!` :`Add Your Own Voice`}

                        </Typography>
                    </Button>


                </Stack>}



                {!showCreateVoice && <Stack

                    paddingTop={5}
                    spacing={3}
                    width={"100%"}
                    direction={"row"}

                    justifyContent={"center"}
                >
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setStory("")
                            setLocation("")
                            setAppearance("")
                        }}

                        sx={{
                            borderColor: "#ffffff",
                            width: window.$isMobile ? "100%" : "75%"
                        }}

                    >
                        <Typography
                            variant={'body1'}
                            sx={{

                                textTransform: "none",
                                typography: { fontFamily: "Inter", fontWeight: 700 },

                                color: "#ffffff"
                            }} >
                            Clear

                        </Typography>
                    </Button>


                    <Button
                        variant="contained"
                        onClick={() => {
                            createStory({
                                story: story,
                                style: styleType,
                                isCustomVoice: isCustomVoice,
                                appearance: appearance,
                                location: location
                            })
                        }}

                        sx={{
                            backgroundColor: "#e8454f",
                            width: window.$isMobile ? "100%" : "75%"
                        }}

                    >
                        <Typography
                            variant={'body1'}
                            sx={{

                                textTransform: "none",
                                typography: { fontFamily: "Inter", fontWeight: 700 },

                                color: "#ffffff"
                            }} >
                            Remix

                        </Typography>
                    </Button>
                </Stack>}
               
                <Stack marginTop={3}>

                </Stack>


            </Stack>


            <Stack
                paddingBottom={10}
            />


        </Box>






    )

}



export default Interview


const textBoxDesign = {
    width: "100%",
    '& label.Mui-focused': {
        color: '#ffffff',

    },
    '& .MuiFormLabel-root': {
        color: '#ffffff',
        opacity: 0.9,
    },
    '& .MuiInputBase-root': {
        color: "#ffffff",
        borderRadius: 1,
        typography: { fontFamily: "Inter", fontWeight: 400 }, fontSize: 16,
        '& .MuiOutlinedInput-root': {
            color: "#ffffff",

        }
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "#006ec2",
        borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: "#006ec2",
            borderWidth: "1px"
        }
    }


}

