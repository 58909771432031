import React, { useEffect } from 'react';
import { TwitterShareButton, FacebookShareButton, EmailShareButton,  WhatsappShareButton, PinterestShareButton } from 'react-share';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PinterestIcon from '@mui/icons-material/Pinterest';

import LinkIcon from '@mui/icons-material/Link';

import { Stack, ClickAwayListener, Tooltip } from '@mui/material';



const SocialShare = ({ snackCard, buttonColor }) => {
 
  

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>

      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied To Clipboard"
          >
            <LinkIcon onClick={() => {
              handleTooltipOpen()
              navigator.clipboard.writeText(`${window.$dataSnackDomain}/?q=${snackCard.mediaId}`)
            }} sx={{ cursor: "pointer", color: buttonColor ?? "#ffffff7f", fontSize: 22, }} />

          </Tooltip>
        </div>
      </ClickAwayListener>

      <EmailShareButton subject={`StorySnacks: Animated Learning Adventures for Kids, Personalized by Parents: ${snackCard.title}`} body={snackCard.description} url={`${window.$dataSnackDomain}/?q=${snackCard.mediaId}`}>
        <EmailIcon sx={{ color: buttonColor ?? "#ffffff7f", fontSize: 18, }} />
      </EmailShareButton>

      <TwitterShareButton title={`StorySnacks: Animated Learning Adventures for Kids, Personalized by Parents: ${snackCard.title}`} url={`${window.$dataSnackDomain}/?q=${snackCard.mediaId}`}>
        <TwitterIcon sx={{ color: buttonColor ?? "#ffffff7f", fontSize: 18, }} />
      </TwitterShareButton>

      <FacebookShareButton hashtag={`StorySnacks: Animated Learning Adventures for Kids, Personalized by Parents: ${snackCard.title}`} url={`${window.$dataSnackDomain}/?q=${snackCard.mediaId}`}>
        <FacebookIcon sx={{ color: buttonColor ?? "#ffffff7f", fontSize: 18, }} />
      </FacebookShareButton>

      <WhatsappShareButton title={`StorySnacks: Animated Learning Adventures for Kids, Personalized by Parents: ${snackCard.title}`} url={`${window.$dataSnackDomain}/?q=${snackCard.mediaId}`}>
        <WhatsAppIcon sx={{ color: buttonColor ?? "#ffffff7f", fontSize: 18, }} />
      </WhatsappShareButton>

    </Stack>
  );
};

export default SocialShare;