import React, { useState, useEffect, useRef } from 'react'
import axios from '../Login/axiosConfig'
import { TextField, Button, Paper, Stack, Typography, Box, Link, Fab } from '@mui/material';
import hero from '../assets/images/hero-little-girl.png'


const SignUp = ({
    openLogin,
    linkCode,
    randomSnack,
    handleScreenChangeClick,
    setShowMessages }) => {

    const [code, setCode] = useState("");

    useEffect(() => {
        if (linkCode) {
            setCode(linkCode)
        }
    }, [linkCode])


    return (

        <Stack width={"100%"} direction={"column"} spacing={4}
            style={{

                paddingLeft: "5%",
                paddingRight: "5%",
                paddingTop: "15px",
                paddingBottom: "15px",

            }}
        >
            <Stack width={"100%"} direction={window.$isMobile ? "column" :"row"} alignItems={"center"} justifyContent={"center"} spacing={4}>

                <Stack width={window.$isMobile ? "100%" :"70%"}>
                    {/* Text on Left */}
                    <Typography

                        paddingBottom={window.$isMobile ? 1 : 1}
                        variant={window.$isMobile ? 'h1' : 'h1'}
                        sx={{

                            textAlign: "left", typography: { fontFamily: "Inter", fontWeight: 900 },
                            lineHeight: window.$isMobile ? '90%' : '100%', color: "#ffffff"
                        }} fontStyle={"normal"}>
                        {/* Managing Employee Access to <span style={{ color: "#ffffff" }}>Restricted </span> GenAI Responses */}
                        {/* Transform <span style={{ color: '#66a8da' }}>Superficial GenAI Content </span> Lacking Depth and References */}
                        Story<span style={{ color: '#66a8da' }}>{window.$isMobile && <br />}Snacks </span>
                    </Typography>

                    <Typography
                        variant={window.$isMobile ? 'h6' : 'h6'}
                        paddingBottom={window.$isMobile ? 4 : 4} 
                        paddingRight={window.$isMobile ? "0%" : "15%"}
                        sx={{

                            textAlign: "left", typography: { fontFamily: "Inter", fontWeight: window.$isMobile ? 600 : 600 },
                            lineHeight: '105%', color: "#ffffff"
                        }} fontStyle={"normal"}>

                        Animated Learning Adventures for Kids, Personalized by Parents
                    </Typography>

                    <Typography
                        variant={'body1'}
                        paddingBottom={window.$isMobile ? 4 : 4} 
                        paddingRight={window.$isMobile ? "0%" : "15%"}
                        sx={{

                            textAlign: "left", typography: { fontFamily: "Inter", fontWeight: window.$isMobile ? 400 : 400 },
                            lineHeight: '120%', color: "#EBEBEB"
                        }} fontStyle={"normal"}>
                         With StorySnacks, parents can easily create their own or remix existing educational animated tales to reflect their culture, appearance, and beliefs, all while using their own voice.
                    </Typography>

                </Stack>


                <Stack>
                    {/* Image on Right */}
                    <Stack width={"100%"}>
                        <img src={hero} alt="Location Image" style={{ width: "100%", borderRadius:5 }} />

                    </Stack>
                </Stack>



            </Stack>

            <Stack>
                {/* Cost, Sign-up button and code form */}

                {/* <TextField
                    focused
                    variant={'outlined'}
                    margin={"none"}
                    sx={textBoxDesign}
                    id="searchInput"
                    type={"text"}
                    onChange={(e) => {
                        setCode(e.target.value)
                    }}
                    value={code}
                    label={`Sign-Up Code`}


                /> */}


                <Stack width={"100%"}
                    marginTop={3}
                    justifyContent={"center"} direction={"row"}>
                    <Button
                        variant="contained"
                        onClick={openLogin}

                        sx={{
                            backgroundColor: "#006ec2",
                            width: window.$isMobile ? "100%" : "75%"
                        }}

                    >
                        <Typography
                            variant={'body1'}
                            sx={{

                                textTransform: "none",
                                typography: { fontFamily: "Inter", fontWeight: 700 },

                                color: "#ffffff"
                            }} >
                            Sign Up Now

                        </Typography>
                    </Button>
                </Stack>
            </Stack>

        </Stack>


    )

}

export default SignUp

const textBoxDesign = {
    width: "100%",
    '& label.Mui-focused': {
        color: '#ffffff',

    },
    '& .MuiFormLabel-root': {
        color: '#ffffff',
        opacity: 0.9,
    },
    '& .MuiInputBase-root': {
        color: "#ffffff",
        borderRadius: 1,
        typography: { fontFamily: "Inter", fontWeight: 400 }, fontSize: 16,
        '& .MuiOutlinedInput-root': {
            color: "#ffffff",

        }
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "#006ec2",
        borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: "#006ec2",
            borderWidth: "1px"
        }
    }


}