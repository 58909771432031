
import React, { useEffect, useState } from 'react'
import { Stack, Typography, Box } from '@mui/material';


const Footer = ({ handleScreenChangeClick, backgroundColor }) => {

    return (
        <Box
            sx={{
                backgroundColor: backgroundColor ?? '#006ec2',

            }}
        >
            <Stack
                paddingLeft={"2.5%"}
                paddingRight={"2.5%"}
                spacing={1}
                paddingTop={window.$isMobile ? "2.5%" : "1.5%"}
                paddingBottom={window.$isMobile ? "2.5%" : "1.5%"}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}>


                <Typography  sx={{
                                textAlign: "left", typography: { fontFamily: "Roboto", fontWeight: 800 },
                                fontSize: 12, lineHeight: '105%', color: "#ffffff"
                            }} fontStyle={"normal"}>
                    {`© 2024 DataSnack`}

                </Typography>
                
                <Stack direction={"column"} spacing={0}>
                    <Typography onClick={() => {
                        handleScreenChangeClick({
                            selectedIndex: "TOS"
                        })
                    }} sx={{ cursor: "pointer", textAlign: "right", typography: { fontFamily: "Roboto", fontWeight: 400 }, fontSize: 10, color: "#ffffff" }} fontStyle={"normal"}>
                        {`Terms of Service`}

                    </Typography>
                    
                    <Typography onClick={() => {
                        handleScreenChangeClick({
                            selectedIndex: "Privacy"
                        })
                    }} sx={{ cursor: "pointer",  textAlign: "right", typography: { fontFamily: "Roboto", fontWeight: 400 }, fontSize: 10, color: "#ffffff" }} fontStyle={"normal"}>
                        {`Privacy`}

                    </Typography>
                    
                    <Typography onClick={() => {
                        handleScreenChangeClick({
                            selectedIndex: "Contact"
                        })
                    }} sx={{ cursor: "pointer",  textAlign: "right", typography: { fontFamily: "Roboto", fontWeight: 400 }, fontSize: 10, color: "#ffffff" }} fontStyle={"normal"}>
                        {`Contact Us`}

                    </Typography>

                </Stack>


            </Stack>
        </Box>
    )

}

export default Footer