export function convertDomainToValidURL(userUrl) {
    try {
        new URL(userUrl);
        return userUrl;
      } catch (err) {
        let mySplit = userUrl.split(".")
        if (mySplit.length < 2) {
          return "badUrl"
        }
        let corrected =  "https://" + userUrl + "/";
        try {
            new URL(corrected);
            return corrected
        } catch(err) {
            console.log(err)
            return "badUrl"
        }
       
      }
}